.m-timeline {
	$root: &;
	$root-item: #{$root}_item;
	$animation: .3s ease-in-out;
	position: relative;

	&_swiper {
		padding: 0 rem(25);
		position: relative;

		@include pc-medium-up {
			padding: 0 rem(40);
		}

		#{$root}-typea & {
			margin-right: rem(5);
		}

		#{$root}-typeb & {
			margin-right: rem(16);
		}

		&::after,
		&::before {
			background-color: $color-white;
			content: '';
			display: inline-block;
			height: rem(21.67);
			margin-top: rem(3);
			position: absolute;
			top: rem(250);
			width: rem(50);
			z-index: z('timeline', 'after');
		}

		&::before {
			left: rem(-24);

			@include pc-medium-up {
				left: rem(-9);
			}
		}

		&::after {
			right: rem(-24);

			@include pc-medium-up {
				right: rem(-9);
			}

			#{$root}-typea & {
				border-bottom: rem(3) solid $color-transparent;
				border-left: rem(6) solid $color-french-plum;
				border-top: rem(3) solid $color-transparent;
				height: 0;
				top: rem(260.5);

				@include sp-medium-down {
					top: rem(280.5);
				}
			}

			#{$root}-typeb & {
				background: url('~assets/images/icons/arsenic-round-right-arrow.svg') left/contain no-repeat $color-white;
			}
		}
	}

	&_item {
		cursor: pointer;
		padding-bottom: rem(80);
		padding-top: rem(250);

		&:nth-child(even) {
			#{$root}-typea & {
				padding-bottom: rem(80);
			}
		}

		#{$root}-typea & {
			@include sp-medium-down {
				padding-top: rem(270);
			}
		}
	}

	&_wrapitem {
		position: relative;

		&::before,
		&::after {
			content: '';
			display: inline-block;
			transition: $animation;
		}

		&::before {
			background-color: $color-french-plum;
			height: rem(1);
			width: 100%;

			#{$root}-typeb & {
				height: rem(4.84);
			}

			#{$root-item} & {
				#{$root}-typeb & {
					background: $color-taupe-gray;
				}
			}

			#{$root-item}:hover &, #{$root-item}-active:nth-child(n) & {
				#{$root}-typeb & {
					background: $color-french-plum;
				}
			}
		}

		&::after {
			left: 50%;
			position: absolute;
			top: 0;
			transform: rotate(-45deg);

			#{$root}-typea & {
				background: $color-french-plum;
				height: rem(6);
				margin-left: rem(-3);
				margin-top: rem(14);
				width: rem(6);
			}

			#{$root}-typeb & {
				background: $color-taupe-gray;
				height: rem(11.62);
				margin-left: rem(-6);
				margin-top: rem(8);
				width: rem(11.62);
			}

			#{$root-item}:hover &, #{$root-item}-active & {
				#{$root}-typeb & {
					background: $color-french-plum;
				}
			}
		}
	}

	&_datetime {
		display: inline-block;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 100%;

		#{$root-item}:nth-child(odd) & {
			top: rem(36);

			@include pc-up {
				#{$root}-typea & {
					top: rem(48);
				}
			}
		}

		#{$root-item}:nth-child(even) & {
			top: rem(-30);

			@include pc-up {
				#{$root}-typea & {
					top: rem(-40);
				}

				#{$root}-typeb & {
					top: rem(-50);
				}
			}
		}

		.a-text {
			transition: $animation;

			#{$root}-typea & {
				color: $color-french-plum;
			}

			#{$root}-typeb & {
				color: $color-arsenic;
				font-size: rem(20);

				@include sp-medium-up {
					font-size: rem(25);
				}
			}

			#{$root-item}:hover &, #{$root-item}-active & {
				#{$root}-typeb & {
					color: $color-french-plum;
				}
			}
		}
	}

	&_content {
		position: relative;

		#{$root-item}:nth-child(even) & {
			padding-bottom: rem(200);
		}

		&::before {
			border: 1px dashed;
			content: '';
			display: inline-block;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			transition: $animation;

			#{$root}-typea & {
				border-color: $color-french-plum;
				height: rem(62);
			}

			#{$root}-typeb & {
				border-color: $color-taupe-gray;
				height: rem(101);
			}

			#{$root-item}:nth-child(even) & {
				#{$root}-typea & {
					top: rem(-4);
				}

				#{$root}-typeb & {
					top: rem(34);
				}
			}

			#{$root-item}:nth-child(odd) & {
				#{$root}-typea & {
					top: rem(-72);
				}

				#{$root}-typeb & {
					top: rem(-146);
				}
			}

			#{$root-item}:hover &, 	#{$root-item}-active & {
				#{$root}-typeb & {
					border-color: $color-french-plum;
				}
			}
		}
	}

	&_wrapcontent {
		background: $color-white;
		display: flex;
		flex-direction: column;
		left: 50%;
		margin-bottom: rem(15);
		position: absolute;
		transform: translateX(-50%);
		z-index: z('normal');
		max-height: rem(99);
		height: auto;
		overflow-x: hidden;
		overflow-y: auto;
		@include scrollbars(4px, $color-gainsboro, $color-white);

		#{$root}-typea & {
			min-width: rem(190);
			padding: rem(15) rem(20);

			@include sp-medium-up {
				min-width: rem(230);
			}
		}

		#{$root}-typeb & {
			border-radius: rem(10);
			box-shadow: 0 0 rem(15) $color-silver-chalice-025;
			min-width: rem(160);
			padding: rem(15) rem(10);

			@include sp-medium-up {
				padding-left: rem(20);
				padding-right: rem(20);
			}
		}

		#{$root-item}:nth-child(even) & {
			transform: translateX(-50%);

			#{$root}-typea & {
				padding-top: rem(6.5);
				top: rem(54);
			}

			#{$root}-typeb & {
				top: calc(100% - #{rem(95)});
				transform: translateX(-50%);
			}
		}

		#{$root-item}:nth-child(odd) & {
			transform: translate(-50%, -100%);

			#{$root}-typea & {
				padding-bottom: rem(6.5);
				top: calc(-100% - #{rem(73)});
			}

			#{$root}-typeb & {
				top: calc(-100% - #{rem(115)});
			}
		}

		.a-image {
			#{$root}-typea & {
				margin-left: auto;
				margin-right: auto;
				width: rem(166);
			}

			#{$root-item}:nth-child(even) & {
				#{$root}-typea & {
					margin-top: rem(20);
					order: 2;
				}
			}

			#{$root-item}:nth-child(odd) & {
				#{$root}-typea & {
					margin-bottom: rem(13);
				}
			}
		}

		.a-text {
			font-size: rem(14);
			letter-spacing: .02em;
			transition: $animation;

			#{$root}-typea & {
				color: $color-dim-gray2;
			}

			#{$root}-typeb & {
				color: $color-taupe-gray;
			}

			#{$root-item}:hover &, #{$root-item}-active & {
				color: $color-french-plum;
			}

			#{$root-item}:nth-child(even) & {
				order: 1;
			}
		}
	}

	&_direction,
	&_navigation {
		align-items: center;
		display: flex;
		justify-content: space-between;

		@include pc-medium-up {
			display: block;
		}
	}

	&_next,
	&_prev {
		cursor: pointer;
		position: absolute;
		transition: opacity $animation;
		z-index: z('normal');

		#{$root}-typea & {
			top: 44.3%;

			@include sp-medium-down {
				top: 46%;
			}
		}

		#{$root}-typeb & {
			top: 45.3%;
		}

		> .a-icon {
			height: rem(24);
			width: rem(24);
		}

		&.swiper-button-disabled {
			opacity: .3;
			pointer-events: none;
		}

		&.swiper-button-lock {
			display: none;
		}
	}

	&_prev {
		left: 0;
		transform: rotate(-180deg);

		@include pc-medium-up {
			left: rem(-24);
		}
	}

	&_next {
		right: 0;

		@include pc-medium-up {
			right: rem(-24);
		}
	}

	&_pagination {
		position: absolute;
		text-align: center;
		width: 100%;
		z-index: z('normal');

		&.swiper-pagination-lock {
			display: none;
		}
	}

	&_bullet {
		background: $color-x11-gray;
		border-radius: 50%;
		cursor: pointer;
		display: inline-block;
		height: rem(12);
		transition: $animation;
		width: rem(12);

		& + & {
			margin-left: rem(16);
		}

		&-active {
			background-color: $color-french-plum;
		}
	}
}
