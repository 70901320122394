.a-icon {
	$root: &;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: rem(20);
	vertical-align: middle;
	width: rem(20);
	$icons: 'arsenic-search',
		'arsenic-right-circle-arrow',
		'arsenic-right-arrow',
		'arsenic-round-right-arrow',
		'lime-green-mail',
		'lime-green-phone',
		'black-times',
		'black-hamburger',
		'grey-down-angle',
		'grey-globe',
		'grey-hamburger',
		'grey-search',
		'grey-times',
		'pink-search',
		'white-down-circle-arrow',
		'arsenic-plus',
		'white-times',
		'white-play',
		'left-french-plum-arrow',
		'right-french-plum-arrow',
		'french-plum-right-circle-arrow',
		'white-right-circle-arrow',
		'french-plum-times',
		'arsenic-bottom-circle-arrow',
		'black-down-circle-arrow',
		'quote-left',
		'quote-right',
		'arsenic-minus',
		'arsenic-continue',
		'white-right-arrow',
		'video-play-icon',
		'video-play-icon-white';

	@each $icon in $icons {
		&-#{$icon} {
			background-image: url('~assets/images/icons/#{$icon}.svg');
		}
	}

	&-clickable {
		cursor: pointer;
		transition: opacity .3s ease-in-out;

		&:hover {
			opacity: .7;
		}
	}

	&-size-large {
		height: rem(30);
		width: rem(30);
	}
}
